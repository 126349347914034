import PropTypes from "prop-types"
import React, { Component } from "react"

// RxJS v6+
import { fromEvent } from "rxjs"
import { debounceTime, map } from "rxjs/operators"

import { cloneDeep, groupBy, xor } from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { ActionCreators } from "../../../redux/actions"
import { WELL_STATUS } from "../../../redux/types"

import HphtIcon from "../../../../assets/images/svg/icons/HPHT.svg"
import HphtIconGrey from "../../../../assets/images/svg/icons/HPHTGrey.svg"
import LockedIcon from "../../../../assets/images/svg/icons/locked.svg"
import LockedIconGrey from "../../../../assets/images/svg/icons/lockedGrey.svg"
import RoundedDropDown from "../../../components/dropdown/RoundedDropDown"
import IconContainer from "../../../components/icons/IconContainer"
import { getReadableWellType } from "../../../lib/WellUtils"

const statusArr = Array.from(WELL_STATUS).map((val) => ({
  name: val[1].name,
  value: val[0],
  color: val[1].color,
  darkmodeColor: val[1].darkmodeColor,
}))

const Checked = ({ fillColor }) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs></defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Button/Dropdown/Active"
          transform="translate(-10.000000, -79.000000)"
          fill={fillColor}
        >
          <g id="Item-2-Copy" transform="translate(10.000000, 76.000000)">
            <g id="Icons/Check/black" transform="translate(0.000000, 3.000000)">
              <path
                d="M15.4278468,5.03142357 L14.7948263,5.66486596 C15.0504692,6.39488701 15.1884352,7.18212483 15.1884352,8.00182607 C15.1884352,11.9623627 11.9624651,15.1884146 8.00202891,15.1884146 C4.03753487,15.1884146 0.811564798,11.9623627 0.811564798,8.00182607 C0.811564798,4.03723148 4.03753487,0.811585381 8.00202891,0.811585381 C9.6576211,0.811585381 11.1833629,1.37523143 12.3966523,2.3207284 L12.9769211,1.74450278 C11.6094344,0.652920439 9.88080142,0 8.00202891,0 C3.59117423,0 0,3.59085952 0,8.00182607 C0,12.4127926 3.59117423,16 8.00202891,16 C12.4128836,16 16,12.4127926 16,8.00182607 C16,6.95488093 15.7971088,5.94892085 15.4278468,5.03142357"
                id="Fill-1"
              ></path>
              <path
                d="M4.72372652,5.78968254 C4.64337151,5.71428571 4.53240507,5.67460317 4.42186379,5.67460317 C4.3155741,5.67460317 4.20503282,5.71428571 4.12467781,5.78968254 C3.95844073,5.94444444 3.95844073,6.1984127 4.12467781,6.3531746 L8.03146175,10 L15.1018521,3.40039683 L15.8756411,2.67857143 C16.041453,2.52380952 16.041453,2.27380952 15.8756411,2.11904762 C15.7906093,2.03968254 15.6843196,2 15.5742035,2 C15.4679138,2 15.3573725,2.03968254 15.2761672,2.11904762 L14.5831584,2.76190476 L13.9798581,3.32936508 L8.03146175,8.87698413 L4.72372652,5.78968254 Z"
                id="Fill-4"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const UnChecked = ({ fillColor }) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs></defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Button/Dropdown/Active"
          transform="translate(-10.000000, -114.000000)"
          fill={fillColor}
        >
          <g id="Item-3-Copy" transform="translate(10.000000, 111.000000)">
            <g
              id="Icons/Check/Uncheck-Button-white"
              transform="translate(0.000000, 3.000000)"
            >
              <path
                d="M8,0.811605965 C4.03611647,0.811605965 0.811605965,4.03611647 0.811605965,8 C0.811605965,11.9634777 4.03611647,15.188394 8,15.188394 C11.9638835,15.188394 15.188394,11.9634777 15.188394,8 C15.188394,4.03611647 11.9638835,0.811605965 8,0.811605965 M8,16 C3.58892158,16 0,12.4110784 0,8 C0,3.58892158 3.58892158,0 8,0 C12.4110784,0 16,3.58892158 16,8 C16,12.4110784 12.4110784,16 8,16"
                id="Fill-1-Copy"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export class SchedulerTopFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedLicenses: [],
      selectedAssets: [],
      selectedAreas: [],
      selectedStatuses: [],
      selectedWellType: [],
      selectedEventType: [],
      search: props.filter.search || "",
      hpht: false,
      has_contract: false,
    }

    this.searchInput = React.createRef()
  }

  componentDidMount() {
    this.debounce(this.searchInput.current, "input", 300).subscribe((value) =>
      this.search(value)
    )
  }

  setStatusFilter = (status) => {
    const { setViewFilter, filter } = this.props

    // well_type
    if (status.object_type === "well_type") {
      // const newFilter = cloneDeep(filter)
      // if (status.value === newFilter.well_type) {
      //   this.clearFilter("well_type")
      // } else {
      //   newFilter.well_type = status.value
      //   setViewFilter(newFilter)
      //   this.setState({
      //     selectedWellType: status.value,
      //   })
      // }
      const newFilter = cloneDeep(filter)
      if (!newFilter.well_type) {
        newFilter.well_type = []
      }
      // Add or remove status
      newFilter.well_type = xor(newFilter.well_type, [status.value])

      this.setState({
        selectedWellType: xor(this.state.selectedWellType, [status.value]),
      })

      if (newFilter.well_type.length > 0) {
        setViewFilter(newFilter)
      } else {
        this.clearFilter("well_type")
      }
    } else if (status.object_type === "event_type") {
      const newFilter = cloneDeep(filter)
      if (!newFilter.event_type) {
        newFilter.event_type = []
      }
      // Add or remove status
      newFilter.event_type = xor(newFilter.event_type, [status.value])

      this.setState({
        selectedEventType: xor(this.state.selectedEventType, [status.value]),
      })

      if (newFilter.event_type.length > 0) {
        setViewFilter(newFilter)
      } else {
        this.clearFilter("event_type")
      }
    } else {
      const newFilter = cloneDeep(filter)
      if (!newFilter.status) {
        newFilter.status = []
      }
      // Add or remove status
      newFilter.status = xor(newFilter.status, [status.value])

      this.setState({
        selectedStatuses: xor(this.state.selectedStatuses, [status.name]),
      })

      if (newFilter.status.length > 0) {
        setViewFilter(newFilter)
      } else {
        this.clearFilter("status")
      }
    }
  }

  setAssetFilter = (asset) => {
    const { setViewFilter, filter } = this.props

    const newFilter = cloneDeep(filter)

    if (asset.object_type === "asset") {
      if (!newFilter.asset) {
        newFilter.asset = []
      }
      // Add or remove status
      newFilter.asset = xor(newFilter.asset, [asset.value])

      this.setState({
        selectedAssets: xor(this.state.selectedAssets, [asset.name]),
      })

      if (newFilter.asset.length > 0) {
        setViewFilter(newFilter)
      } else {
        this.clearFilter("asset")
      }
    } else {
      if (!newFilter.area) {
        newFilter.area = []
      }

      newFilter.area = xor(newFilter.area, [asset.value])

      this.setState({
        selectedAreas: xor(this.state.selectedAssets, [asset.name]),
      })

      if (newFilter.area.length > 0) {
        setViewFilter(newFilter)
      } else {
        this.clearFilter("area")
      }
    }
  }

  setLicenseFilter = (license) => {
    const { setViewFilter, filter } = this.props

    const newFilter = cloneDeep(filter)
    if (!newFilter.license) {
      newFilter.license = []
    }
    // Add or remove status
    newFilter.license = xor(newFilter.license, [license.value])

    this.setState({
      selectedLicenses: xor(this.state.selectedLicenses, [license.name]),
    })

    if (newFilter.license.length > 0) {
      setViewFilter(newFilter)
    } else {
      this.clearFilter("license")
    }
  }

  renderStatus = (key, val) => {
    const { darkMode } = this.props
    if (val.type === "headline") {
      return (
        <div
          key={key}
          className="RoundedDropDown__Option RoundedDropDown__Option--headline"
        >
          {val.name}
        </div>
      )
    }
    if (val.type === "separator") {
      return (
        <div
          key={key}
          className="RoundedDropDown__Option RoundedDropDown__Option--separator"
        ></div>
      )
    }

    if (val.object_type && val.object_type === "well_type") {
      const fillColor = darkMode ? "#CDCFD3" : "#080808"
      return (
        <div
          onClick={() => this.setStatusFilter(val)}
          className={`RoundedDropDown__Option RoundedDropDown__Option--status${
            val.selected ? " RoundedDropDown__Option--selected" : ""
          }`}
          key={`${key}-statusfilter-option`}
        >
          {val.selected ? (
            <Checked fillColor={fillColor} />
          ) : (
            <UnChecked fillColor={fillColor} />
          )}
          <span className="RoundedDropDown__Option-Value">{val.name}</span>
        </div>
      )
    }
    if (val.object_type && val.object_type === "event_type") {
      const fillColor = darkMode ? "#CDCFD3" : "#080808"
      return (
        <div
          onClick={() => this.setStatusFilter(val)}
          className={`RoundedDropDown__Option RoundedDropDown__Option--status${
            val.selected ? " RoundedDropDown__Option--selected" : ""
          }`}
          key={`${key}-statusfilter-option`}
        >
          {val.selected ? (
            <Checked fillColor={fillColor} />
          ) : (
            <UnChecked fillColor={fillColor} />
          )}
          <span className="RoundedDropDown__Option-Value">{val.name}</span>
        </div>
      )
    }

    return (
      <div
        onClick={() => this.setStatusFilter(val)}
        className={`RoundedDropDown__Option RoundedDropDown__Option--status${
          val.selected ? " RoundedDropDown__Option--selected" : ""
        }`}
        key={`${key}-statusfilter-option`}
      >
        <span
          style={{ background: val.color }}
          className="RoundedDropDown__Option--status-color"
        ></span>
        <span className="RoundedDropDown__Option-Value">{val.name}</span>
      </div>
    )
  }

  renderAsset = (key, val) => {
    if (val.type === "headline") {
      return (
        <div
          key={key}
          className="RoundedDropDown__Option RoundedDropDown__Option--headline"
        >
          {val.name}
        </div>
      )
    }
    if (val.type === "separator") {
      return (
        <div
          key={key}
          className="RoundedDropDown__Option RoundedDropDown__Option--separator"
        ></div>
      )
    }

    const { darkMode } = this.props
    const fillColor = darkMode ? "#CDCFD3" : "#080808"
    return (
      <div
        onClick={() => this.setAssetFilter(val)}
        className="RoundedDropDown__Option RoundedDropDown__Option--asset"
        key={key}
      >
        {val.selected ? (
          <Checked fillColor={fillColor} />
        ) : (
          <UnChecked fillColor={fillColor} />
        )}
        <span className="RoundedDropDown__Option-Value">{val.name}</span>
      </div>
    )
  }

  renderLicense = (key, val) => {
    const { darkMode } = this.props
    const fillColor = darkMode ? "#CDCFD3" : "#080808"

    return (
      <div
        onClick={() => this.setLicenseFilter(val)}
        className="RoundedDropDown__Option RoundedDropDown__Option--asset"
        key={key}
      >
        {val.selected ? (
          <Checked fillColor={fillColor} />
        ) : (
          <UnChecked fillColor={fillColor} />
        )}
        <span className="RoundedDropDown__Option-Value">{val.name}</span>
      </div>
    )
  }

  search = (event) => {
    const { setViewFilter, filter } = this.props

    const newFilter = cloneDeep(filter)
    if (!newFilter.search) {
      newFilter.search = ""
    }
    // Add or remove status
    newFilter.search = event.target.value

    this.setState({
      search: event.target.value,
    })

    if (!newFilter.search) {
      delete newFilter.search
    }

    setViewFilter(newFilter)
  }

  searchUpdate = (event) => {
    this.setState({
      search: event.target.value,
    })
  }

  toggleBooleanFilter = (type) => {
    const { setViewFilter, filter } = this.props

    const newFilter = cloneDeep(filter)
    if (!newFilter[type]) {
      newFilter[type] = true
    } else {
      delete newFilter[type]
    }
    this.setState({ [type]: !!newFilter[type] })

    setViewFilter(newFilter)
  }

  debounce = (el, e, time = 1000) => {
    // attach an observer
    const eventObserver = fromEvent(el, e)

    // call the debounveTime method on the observable passing the time,
    // map tru the events an get the value of the input
    // return an observable to be subscribed to
    return eventObserver.pipe(
      debounceTime(time),
      map((event) => event)
    )
  }

  clearFilter = (type) => {
    const { setViewFilter, filter } = this.props

    const newFilter = cloneDeep(filter)

    switch (type) {
      case "license":
        this.setState({
          selectedLicenses: null,
        })
        delete newFilter.license
        break
      case "asset_area":
        this.setState({
          selectedAssets: null,
          selectedAreas: null,
        })
        delete newFilter.asset
        delete newFilter.area
        break
      case "asset":
        this.setState({
          selectedAssets: null,
        })
        delete newFilter.asset
        break
      case "area":
        this.setState({
          selectedAreas: null,
        })
        delete newFilter.area
        break
      case "status":
        this.setState({
          selectedStatuses: null,
        })
        delete newFilter.status
        break
      case "well_type":
        this.setState({
          selectedWellType: null,
        })
        delete newFilter.well_type
        break
      case "event_type":
        this.setState({
          selectedEventType: null,
        })
        delete newFilter.event_type
        break
      case "status_and_well_type":
        this.setState({
          selectedWellType: null,
          selectedStatuses: null,
          selectedEventType: null,
        })
        delete newFilter.well_type
        delete newFilter.status
        delete newFilter.event_type
        break
      default:
    }

    setViewFilter(newFilter)
  }

  render() {
    const {
      assets,
      areas,
      filter,
      licenses,
      darkMode,
      domain: { data: domainData },
      wellStatusMapping,
    } = this.props

    const areaArr = groupBy(areas, "country_id")

    const statuses = statusArr.map((val) => ({
      name: wellStatusMapping[val.value],
      value: val.value,
      color: darkMode ? val.darkmodeColor : val.color,
      selected: !!(
        filter &&
        filter.status &&
        filter.status.includes(val.value)
      ),
    }))

    statuses.unshift({
      name: "Status",
      type: "headline",
    })
    statuses.unshift({
      name: "",
      type: "separator",
    })
    if(domainData.activeWellTypes) {
      domainData.activeWellTypes.forEach((e) => {
        statuses.unshift({
          name: getReadableWellType(e),
          value: e,
          type: "object",
          object_type: "well_type",
          selected: !!(
            filter &&
            filter.well_type &&
            filter.well_type.includes(e)
          ),
        })
      })
    }
    statuses.unshift({
      name: "Well type",
      type: "headline",
    })

    statuses.push({
      name: "",
      type: "separator",
    })
    statuses.push({
      name: "Event type",
      type: "headline",
    })
    if(domainData.eventTypes) {
      domainData.eventTypes.forEach((e) => {
        statuses.push({
          name: e,
          value: e,
          type: "object",
          object_type: "event_type",
          selected: !!(
            filter &&
            filter.event_type &&
            filter.event_type.includes(e)
          ),
        })
      })
    }

    const showAssets = assets.data && assets.data.length > 1

    const assetArr = showAssets
      ? assets.data.map((val) => ({
          name: val.name,
          value: val.id,
          type: "object",
          object_type: "asset",
          selected: !!(filter && filter.asset && filter.asset.includes(val.id)),
        }))
      : []

    if (showAssets) {
      assetArr.unshift({
        name: "Asset",
        type: "headline",
      })
      assetArr.unshift({
        name: "",
        type: "separator",
      })
    }

    Object.values(areaArr).forEach((e) => {
      e.forEach((asset) => {
        assetArr.unshift({
          name: asset.name,
          value: asset.id,
          type: "object",
          object_type: "area",
          selected: !!(filter && filter.area && filter.area.includes(asset.id)),
        })
      })
      if (Object.values(areaArr).length > 1) {
        assetArr.unshift({
          name: e[0].country.name,
          type: "headline",
        })
      }
    })
    // assetArr.unshift({
    //   name: "Barents sea",
    //   value: 3,
    //   type: "object",
    //   object_type: "area",
    //   selected: !!(filter && filter.area && filter.area.includes(3)),
    // })
    // assetArr.unshift({
    //   name: "Norwegian sea",
    //   value: 2,
    //   type: "object",
    //   object_type: "area",
    //   selected: !!(filter && filter.area && filter.area.includes(2)),
    // })
    // assetArr.unshift({
    //   name: "North sea",
    //   value: 1,
    //   type: "object",
    //   object_type: "area",
    //   selected: !!(filter && filter.area && filter.area.includes(1)),
    // })
    // assetArr.unshift({
    //   name: "Area",
    //   type: "headline",
    // })

    const licensesArr = licenses.data
      ? licenses.data.map((val) => ({
          name: val.name,
          value: val.id,
          selected: !!(
            filter &&
            filter.license &&
            filter.license.includes(val.id)
          ),
        }))
      : []

    let areaAssetLength = 0
    areaAssetLength += filter.asset ? filter.asset.length : 0
    areaAssetLength += filter.area ? filter.area.length : 0

    let statusWellTypeLength = 0
    statusWellTypeLength += filter.well_type ? 1 : 0
    statusWellTypeLength += filter.event_type ? filter.event_type.length : 0
    statusWellTypeLength += filter.status ? filter.status.length : 0

    const assetAreaFilterString = showAssets ? "Asset & area" : "Area"

    return (
      <div className="SchedulerTop__Filter">
        <div className="Row__Container">
          <RoundedDropDown
            clearFunction={() => this.clearFilter("license")}
            label={
              filter.license ? `License ${filter.license.length}` : "License"
            }
            hasSelectedValue={!!filter.license}
            renderoption={this.renderLicense}
            data={licensesArr}
          />
          <RoundedDropDown
            clearFunction={() => this.clearFilter("status_and_well_type")}
            label={
              filter.status || filter.well_type || filter.event_type
                ? `Status/type ${statusWellTypeLength}`
                : "Status, well & event-type"
            }
            hasSelectedValue={
              !!filter.status || !!filter.well_type || !!filter.event_type
            }
            renderoption={this.renderStatus}
            data={statuses.filter((s) => s.value !== "additional_scope")}
          />
          <a
            onClick={() => this.toggleBooleanFilter("hpht")}
            className={`SchedulerTop__FilterBtn${filter.hpht ? " active" : ""}`}
          >
            {darkMode
              ? IconContainer(HphtIconGrey, 12, 12)
              : IconContainer(HphtIcon, 10, 10)}
          </a>
          <a
            onClick={() => this.toggleBooleanFilter("has_contract")}
            className={`SchedulerTop__FilterBtn${
              filter.has_contract ? " active" : ""
            }`}
          >
            {darkMode
              ? IconContainer(LockedIconGrey, 12, 12)
              : IconContainer(LockedIcon, 10, 10)}
          </a>
        </div>
        <div className="Row__Container">
          <RoundedDropDown
            clearFunction={() => this.clearFilter("asset_area")}
            label={
              filter.asset || filter.area
                ? `${assetAreaFilterString} ${areaAssetLength}`
                : assetAreaFilterString
            }
            hasSelectedValue={!!filter.asset || !!filter.area}
            renderoption={this.renderAsset}
            data={assetArr}
          />
          <div className="SchedulerTop__FilterSearchWrapper">
            <svg
              width="14px"
              height="14px"
              viewBox="0 0 14 14"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <defs></defs>
              <g
                id="Symbols"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Filter/Search/Inactive"
                  transform="translate(-10.000000, -11.000000)"
                  fill={darkMode ? "#CDCFD3" : "#1C2226"}
                >
                  <g
                    id="Icons/Filter/Search"
                    transform="translate(10.000000, 11.000000)"
                  >
                    <path
                      d="M2.20378454,10.3405698 C1.12747065,9.22012544 0.557921217,7.75381761 0.600375821,6.21114265 C0.642232472,4.66876255 1.29101057,3.23429893 2.4268207,2.17312019 C3.52376394,1.14820847 4.94748803,0.589460561 6.45223464,0.589460561 C6.50605034,0.589460561 6.55986603,0.590345122 6.6139807,0.591819391 C8.17792458,0.63309892 9.63244321,1.27293161 10.7084581,2.39308111 C11.784772,3.5132306 12.3543214,4.97953843 12.3121658,6.52250824 C12.2700102,8.06488834 11.6212321,9.49905711 10.485422,10.5605307 C8.14085155,12.7515891 4.42607375,12.6534028 2.20378454,10.3405698 M13.9188633,13.5034662 L11.2128308,10.662845 C12.2652266,9.53296529 12.8673644,8.08700238 12.910118,6.53813549 C12.9564593,4.83830347 12.3286095,3.22191507 11.1422724,1.98765717 C9.95653328,0.752809553 8.35402149,0.0481090285 6.63012541,0.00211183941 C4.91400271,-0.0409368119 3.26784041,0.57530758 2.01572859,1.74528736 C0.763915737,2.91467744 0.0490639282,4.49509368 0.00242365963,6.19522055 C-0.0442166089,7.89534742 0.58363316,9.51144097 1.76997025,10.7459937 C3.03732985,12.0654644 4.74597815,12.7306545 6.45791519,12.7306545 C8.00810617,12.7306545 9.55889509,12.1798676 10.7894806,11.0794733 L13.4832551,13.9071211 C13.5418544,13.9690404 13.6210831,14 13.7009097,14 C13.7744578,14 13.8480059,13.9734632 13.9057083,13.9200946 C14.0258967,13.8086399 14.0321752,13.6219975 13.9188633,13.5034662"
                      id="Fill-1"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
            <input
              value={this.state.search}
              // value={filter && filter.search ? filter.search : ''}
              onChange={this.searchUpdate}
              ref={this.searchInput}
              type="text"
              placeholder="Search"
              className={`SchedulerTop__FilterSearch${
                this.state.search ? " hasSearch" : ""
              }`}
            />
          </div>
        </div>
      </div>
    )
  }
}

SchedulerTopFilter.propTypes = {
  blocks: PropTypes.object,
  assets: PropTypes.object,
  areas: PropTypes.array,
  licenses: PropTypes.object,
  filter: PropTypes.object,
  setViewFilter: PropTypes.func,
  toggleViewFilter: PropTypes.func,
  darkMode: PropTypes.bool,
  domain: PropTypes.object,
  wellStatusMapping: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch)
}

function mapStateToProps(state) {
  return {
    blocks: state.schedulerData.blocks,
    assets: state.schedulerData.assets,
    areas: state.schedulerData.areas,
    licenses: state.schedulerData.licenses,
    filter: state.filters.filter,
    darkMode: state.calendar.darkMode,
    domain: state.application.domain,
    wellStatusMapping: state.application.domain.data.wellStatusMapping,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerTopFilter)
